import React, { useContext, useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { UserContext } from "../context/userContext";
import { Spinner } from "react-bootstrap";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    const { fetchData } = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchWithLoading = async () => {
        await fetchData();
        setLoading(false);
      };
  
      fetchWithLoading();
    }, [fetchData]);
  
    if (loading) if (loading)
      return (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner />
        </div>
      );

    return (
        auth.isAuthenticated &&  auth?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : Object.entries(auth.userData).length > 0
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/" state={{ from: location }} replace />
    );
    // return (
    //     auth.isAuthenticated
    //         ? <Outlet />
    //         : Object.entries(auth.userData).length > 0
    //             ? <Navigate to="/unauthorized" state={{ from: location }} replace />
    //             : <Navigate to="/" state={{ from: location }} replace />
    // );
}

export default RequireAuth;