import {
  GET_USER_ACCOUNT_DETAILS,
  REACT_APP_API_ENDPOINT,
  SAVE_LOGIN_LOGOUT_TIME
} from "../action/apiPath";
import  { createContext, useEffect, useState } from "react";
import { login_time } from "../pages/Login/login";

import { CallWithOutAuth, CallWithAuth } from "../action/apiActions";
import { GET_CURRENT_USER_USER_TYPE, GET_USER_TYPES, GET_DEPARTMENTS } from "../action/apiPath";
import axios from "axios";
import axiosInstance from "../utils/axios";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { setAccessToken } from "../utils/accessToken";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [status, setStatus] = useState(0);
  const [shiftData, setShiftData] = useState(null);
  const [maintainanceOngoing, setMaintainanceOngoing] = useState(false)
  const [myRoles, setMyRoles] = useState([])
  const [userType, setUserType] = useState([])
  const [departments, setDepartments] = useState([])



  const [auth, setAuth] = useState({
    isAuthenticated: false,
    isInitialized: true,
    userId: "",
    roles: [],
    userData: {},
    socketData: {},
    ipAddress: "",
  });

  let logout_time;
  // let login_time;
  const send_availabe_time = async (logout_time) => {
    if (auth.userData.email && login_time && logout_time && auth.userId) {
      const payload = {
        Id: auth.userId,
        Email: auth.userData.email,
        LoginTime: login_time,
        LogoutTime: logout_time,
      };

      const response = await CallWithOutAuth("POST", SAVE_LOGIN_LOGOUT_TIME, payload);

      if (response.res.data.status === 200) {
        //   setEquimentsList(response.res.data.data);
      }
      return;
    }

  };


  const logout = async () => {
    // const res = await axios.get("https://geolocation-db.com/json/");
    const res = await axios.get(REACT_APP_API_ENDPOINT + "api/myip");
    auth?.socketData?.current?.emit("disconnection", {
      id: auth?.userId,
      ip: res?.data,
      // ip: res?.data?.IPv4,
    });
    auth?.socketData?.current?.on("disconnect", () => {
      // console.log(auth?.socketData?.current.connected); // false
    });
    delete axiosInstance.defaults.headers.common.Authorization;
    localStorage.clear();
    setAccessToken("");
    setAuth({
      isAuthenticated: false,
      isInitialized: false,
      userId: "",
      roles: "",
      userData: {},
      socketData: {},
      ipAddress: "",
    });

    logout_time = new Date();
    send_availabe_time(logout_time);
    toast.success("User logout Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    const queryParams = new URLSearchParams(window.location.search);
    const sid = queryParams.get('id');
    if (sid && window.location.pathname == '/changepassword') {
      localStorage.clear();
      setAccessToken("");
      return false;
    }
    const decoded = jwt_decode(accessToken);
    const currentTime = Date.now() / 1000;
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    return decoded.exp > Math.ceil(currentTime);
  };

  //need to get userData at the time of page reload
  //until loding screen will mount here.


  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      isValidToken(localStorage.getItem("accessToken"))
    ) {
      CallWithAuth("GET", GET_USER_ACCOUNT_DETAILS).then(
        async (response) => {
          if (response?.res?.data?.status === 200) {
            // const res = await axios.get("https://geolocation-db.com/json/");

            if (response.res.data.data.maintainance) {
              setMaintainanceOngoing(true)
            }
            const res = await axios.get(REACT_APP_API_ENDPOINT + "api/myip");
            let socket = {};
            // console.log(API_END_POINT_SOCKET)
            // '/chat/socket.io'
            // socket.current = openSocket(
            //   process.env.REACT_APP_API_ENDPOINT,
            //   { path: "" },
            //   { query: { data: "web" } }
            // );
            // socket.current.on("connect", () => {
            //   console.log(
            //     "socket.current.connected--",
            //     socket.current.connected
            //   ); // true
            //   if (socket.current.connected) {
            //     // alert(response.res.data.data.result.userId)
            //     socket.current.emit("useradd", {
            //       id: response.res.data.data._id,
            //       from: "web",
            //       ip: res?.data,
            //       // ip: res?.data?.IPv4,
            //     });
            //     socket.current.on("useradded", (data) => {
            //       // console.log(data);
            //     });
            //     // localStorage.setItem('socket', socket);
            //   }
            // });

            setAuth({
              ...auth,
              isAuthenticated: true,
              isInitialized: false,
              userId: response.res.data.data._id,
              roles: response.res.data.data.userType,
              // roles: response.res.data.data.isAdmin || (response.res.data.data.userType && (response.res.data.data.userType.includes(300) || response.res.data.data.userType.includes(400) || response.res.data.data.userType.includes(500))) ? ["Admin"] : [""],
              userData: response.res.data.data,
              socketData: socket,
              ipAddress: res?.data,
              // ipAddress: res?.data?.IPv4,
            });
          } else {
            logout();
          }
        }
      );
    } else {
      // logout();
      setAuth({
        ...auth,
        isAuthenticated: false,
        isInitialized: false,
        userId: "",
        roles: "",
        userData: {},
      });
    }
  }, []);


  const getmyroles = async () => {
    const response = await CallWithAuth('GET', GET_CURRENT_USER_USER_TYPE);
    const data = response?.res?.data?.data
    setMyRoles(data)
    return;
  }

  const getUserTypes = async () => {
    const response = await CallWithAuth('GET', GET_USER_TYPES)
    if (response?.res?.data?.status == 200) {
      setUserType(response?.res?.data?.data)
    }
  }

  const getDepartments = async () => {
    const response = await CallWithAuth('GET', GET_DEPARTMENTS)
    if (response?.res?.data?.status == 200) {
      setDepartments(response?.res?.data?.data)
    }
  }
  useEffect(() => {
    getUserTypes()
    getmyroles()
    getDepartments()
  }, [auth])

  if (auth.isInitialized) {
    return (
      <h1
        style={{
          position: "absolute",
          top: "40%",
          left: "40%",
        }}
      >
        Loading screen......
      </h1>
    );
  }

  const setSocketData = (data) => {
    setAuth({
      ...auth,
      socketData: data,
    });
  };



  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        logout,
        setSocketData,
        status,
        shiftData,
        setStatus,
        setShiftData,
        myRoles,
        userType,
        departments,
        maintainanceOngoing,
        setMaintainanceOngoing
      }}
    >
      {/* <NetworkDetector /> */}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;


