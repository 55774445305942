import './index.css';
import './css/allcss.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './App';
import { AuthProvider } from './context/AuthProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { UserState } from './context/userContext';
import { GlobalProvider } from './context/globalContext';
import { NotificationProvider } from "context/NotificationProvider";
// import { debugContextDevtool } from 'react-context-devtool';
// const container = document.getElementById("root");
// const options = [{
//   debugReducer: true,
//   debugContext: true
// }]
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <UserState>
          <GlobalProvider>
            <NotificationProvider>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </NotificationProvider>
          </GlobalProvider>
        </UserState>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
// debugContextDevtool(container, options);
