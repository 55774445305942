import { useState } from "react";
import alertIcon from "../../../../assets/svgs/notifications/alert.svg";
import InfoIcon from "../../../../assets/svgs/notifications/infoIcon.svg";
import closeIcon from "../../../../assets/svgs/notifications/roundClose.svg";
import moment from "moment-timezone";
import useNotificationContext from "hooks/useNotificationContext";

const NotificationToast = ({
  data,
  isAlert,
  cardStyle = {},
  isCloseVisible = true,
  isOnClickDisabled = false,
}) => {
  const {
    removeNotification,
    setShowNotificationSummary,
    clearAllNotifications,
  } = useNotificationContext();
  const [hoveredAction, setHoveredAction] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredAction(id);
  };

  const handleMouseLeave = () => {
    setHoveredAction(null);
  };

  const getTimeDifference = (notificationTime) => {
    const now = moment.tz("Pacific/Auckland");
    const time = moment.tz(notificationTime, "Pacific/Auckland");

    const diffInMinutes = now.diff(time, "minutes");
    const diffInHours = now.diff(time, "hours");

    if (diffInMinutes < 1) {
      return "1m ago";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes}m ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours}h ago`;
    } else {
      const diffInDays = now.diff(time, "days");
      return `${diffInDays}d ago`;
    }
  };

  return (
    <div style={{ zIndex: "9999", position: "relative", ...cardStyle }}>
      {data.length > 0 &&
        data?.map((item) => (
          <div
            className="my-3 d-flex align-items-center justify-content-between"
            key={item._id}
            style={{
              background: isAlert ? "#FFF5F4" : "#E5EFFD",
              border: isAlert ? "1px solid #FAE4E0" : "1px solid #CFE4F8",
              borderRadius: "10px",
              boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.15)",
              height: "80px",
              width: "435px",
            }}
            onMouseEnter={() => handleMouseEnter(item._id)}
            onMouseLeave={handleMouseLeave}
          >
            <div 
            className="pl-4 d-flex align-items-center justify-content-between w-100" 
            onClick={() => {
              if (!isOnClickDisabled) {
                clearAllNotifications();
                setShowNotificationSummary(true);
              }
            }}>
              <div
                style={{
                  width: "15%",
                }}
              >
                {isAlert ? (
                  <img
                    src={alertIcon}
                    alt="alertIcon"
                    style={{ marginRight: "10px" }}
                  />
                ) : (
                  <img
                    src={InfoIcon}
                    alt="infoIcon"
                    style={{ marginRight: "10px" }}
                  />
                )}
              </div>
              <div style={{ width: "85%" }}>
                <div
                  style={{
                    font: "normal normal bold 14px/20px Open Sans",
                    color: "#1A1A1A",
                  }}
                >
                  {item.title}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    style={{
                      font: "normal normal normal 14px/20px Open Sans",
                      color: "#1A1A1A",
                    }}
                  >
                    {item.description}
                  </div>
                  <div
                    style={{
                      font: "normal normal normal 12px/20px Open Sans",
                      color: "#1A1A1A",
                      float: "right",
                    }}
                  >
                    {getTimeDifference(item.createdAt)}
                  </div>
                </div>
              </div>
            </div>
            {isCloseVisible && (
              <div
                style={{
                  position: "relative",
                  bottom: "38px",
                  left: "7px",
                  cursor: "pointer",
                  visibility: hoveredAction === item._id ? "visible" : "hidden",
                  opacity: hoveredAction === item._id ? 1 : 0,
                  transition: "opacity 0.3s ease",
                }}
              >
                <img
                  style={{ width: "25px", height: "25px" }}
                  src={closeIcon}
                  alt="preview"
                  onClick={() => removeNotification(item._id)}
                />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default NotificationToast;
