import ShiftPlanningEnabled from "../../assets/svgs/dashboard-Embeded/Shift Management.svg";
import DumpEnabled from "../../assets/svgs/dashboard-Embeded/Dump Management.svg";
import KPIEnabled from "../../assets/svgs/dashboard-Embeded/KPI Dashboard.svg";
import Maintenance from "../../assets/svgs/dashboard-Embeded/Maintenance.svg";
import PreStartEnabled from "../../assets/svgs/dashboard-Embeded/Pre-Start Checks.svg";
import ManageUserEnabled from "../../assets/svgs/dashboard-Embeded/Manage Users.svg";
import AssetEnabled from "../../assets/svgs/dashboard-Embeded/Asset Tracking.svg";

import KPIDisabled from "../../assets/svgs/dashboard-Disabled/KPI Dashboard.svg";
import MaintenanceDisabled from "../../assets/svgs/dashboard-Disabled/Maintenance.svg";
import PreStartDisabled from "../../assets/svgs/dashboard-Disabled/Pre-Start Checks.svg";
import AssetDisabled from "../../assets/svgs/dashboard-Disabled/Asset Tracking.svg";

import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import './landingPage.scss';
import AuthLayout from '../../components/AuthLayout';
import { departmentList } from '../../utils/constants';


const departments = () => [
  { name: "KPI Dashboard", code: 100, allowedRoles: [300, 400, 500] },
  { disabled: process.env.REACT_APP_ENV != "stage", name: "Asset Tracking", code: 400, allowedRoles: [300, 400, 500] },
  { name: "Shift Management", code: 200, allowedRoles: [300, 400, 500] },
  { name: "Dump Management", code: 300, allowedRoles: [300, 400, 500] },
  { name: "Pre-Start Checks", code: 600, allowedRoles: [300, 400, 500] },
  { name: "Maintenance", code: 500, allowedRoles: [300, 400, 500, 600] },
  { name: "User Management", code: 700, allowedRoles: [300, 400, 500] },
];

const icons = {
  100: [KPIEnabled],
  200: [ShiftPlanningEnabled],
  300: [DumpEnabled],
  400: [process.env.REACT_APP_ENV != "stage" ? AssetDisabled : AssetEnabled],
  500: [Maintenance],
  600: [PreStartEnabled],
  700: [ManageUserEnabled],
};

const links = {
  100: '/management-kpis/asset-analytics',
  200: '/shift-management/shift-planning',
  300: '/dump-management/landing-page',
  400: '/asset-tracking',
  600: '/pre-start-check/landing-page',
  700: '/user-management/landing-page',
  100: "/management-kpis/kpi-dashboard",
  200: "/shift-management/shift-planning",
  300: "/dump-management/landing-page",
  500: "/maintenance/workshop-jobs",
  600: "/pre-start-check/landing-page",
  700: "/user-management/landing-page",
};

const LandingPage = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();

  const routes = departmentList();
  const onDepartmentClick = (code) => {
    return navigate(links[code]);
  };
  return (
    <AuthLayout noSideBar showlogo pageName="Landing ">
      <div className="h-auto landing-container" >

        <div className="home-container">
          <div className="department-container">
            {routes.map(({ name, code, allowedRoles, disabled }, index) => (
              <div
                id={code}
                style={{ userSelect: 'none' }}
                value={code}
                onClick={() => !disabled && onDepartmentClick(code)}
                key={name}
                className={`department-item
                 ${disabled ||
                    !allowedRoles?.find((role) => auth.roles.includes(role))
                    ? "department-disabled"
                    : ""
                  }
               `}
              >
                <div className="icon-wrapper">
                  <img
                    className="department-icon"
                    alt={name}
                    src={icons[code]}
                    key={code}
                  />
                </div>
                <p style={{ color: disabled ? "#868686" : "#000000" }} className="department-name">
                  {name}
                </p>
              </div>

            ))}
          </div>
        </div>
      </div >
    </AuthLayout >
  );
};

export default LandingPage;