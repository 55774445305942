import React, { useState } from "react";
import styles from "../Alerts/Alerts.module.css";
import NotificationToast from "../NotificationToast/NotificationToast";
import useNotificationContext from "hooks/useNotificationContext";

const Alerts = () => {
  const [collapsed, setCollapsed] = useState(true); // State to manage collapse status
  const {
    getAlerts,
    getInformation,
    getNotifications,
    clearAllAlerts,
    clearAllInformation,
    setShowNotificationSummary,
    clearAllNotifications,
  } = useNotificationContext();

  const alerts = getAlerts(),
    information = getInformation(),
    notifications = getNotifications();

  const handleShowLess = () => {
    setCollapsed(true); // Collapse the cards
  };

  const handleExpand = () => {
    if (notifications.length > 1) {
      setCollapsed(false); // Expand the cards
    } else {
      clearAllNotifications();
      setShowNotificationSummary(true);
    }
  };

  return (
    notifications.length !== 0 && (
      <div className={`px-4 py-3 ${styles.alertContainer}`}>
        {!collapsed ? (
          <div>
            {alerts.length !== 0 && (
              <div className="py-4">
                <div className="d-flex align-items-center justify-content-between">
                  <div className={styles.alertTitle}>Alerts</div>
                  <div
                    className="py-3 d-flex align-items-center justify-content-between"
                    style={{ gap: "10px" }}
                  >
                    <div
                      className={styles.showLessBtn}
                      onClick={handleShowLess}
                    >
                      Show Less
                    </div>
                    <div
                      className={styles.clearAllBtn}
                      onClick={clearAllAlerts}
                    >
                      Clear All
                    </div>
                  </div>
                </div>
                <NotificationToast data={alerts} isAlert={true} />
              </div>
            )}

            {information.length !== 0 && (
              <div className="">
                <div className="d-flex align-items-center justify-content-between">
                  <div className={styles.alertTitle}>Information</div>
                  <div
                    className="py-3 d-flex align-items-center justify-content-between"
                    style={{ gap: "10px" }}
                  >
                    {alerts.length === 0 && (
                      <div
                        className={styles.showLessBtn}
                        onClick={handleShowLess}
                      >
                        Show Less
                      </div>
                    )}
                    <div
                      className={styles.clearAllBtn}
                      onClick={clearAllInformation}
                    >
                      Clear All
                    </div>
                  </div>
                </div>
                <NotificationToast data={information} isAlert={false} />
              </div>
            )}
          </div>
        ) : (
          <div
            className="collapsed-card"
            style={{
              cursor: "pointer",
            }}
            onClick={handleExpand}
          >
            <NotificationToast
              data={[notifications[0]]}
              isAlert={notifications[0].type === "alert"}
              isCloseVisible={false}
              isOnClickDisabled={true}
            />
            {notifications.length > 1 && (
              <>
                <div
                  className={styles.shadow}
                  style={{
                    width: "395px",
                    height: "80px",
                    bottom: "74px",
                    left: "20px",
                  }}
                ></div>
                <div
                  className={styles.shadow}
                  style={{
                    width: "415px",
                    height: "80px",
                    bottom: "163px",
                    left: "10px",
                  }}
                ></div>
              </>
            )}
          </div>
        )}
      </div>
    )
  );
};

export default Alerts;
