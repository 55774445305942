import moment from "moment";
import { dayShiftData, nightShiftData } from "./constants";

export const formatEpochTimeToNZ = (epochTime, isTimeFirst = true) => {
  const date = new Date(epochTime * 1000);
  const time = date.toLocaleTimeString("en-US", {
    timeZone: "Pacific/Auckland",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const dateString = date.toLocaleDateString("en-US", {
    timeZone: "Pacific/Auckland",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  if (isTimeFirst) return `${time}`;
  else return `${dateString}, ${time}`;
};

export const formatData = (data, type, excavator) => {
  let graphData = type == "Day" ? [...dayShiftData] : [...nightShiftData];
  let result = [];
  // console.log("result it", Object.keys(excavator?.hourlyData).length, graphData);
  if (
    (excavator?.hourlyData &&
      Object?.keys(excavator?.hourlyData)?.length === 0) ||
    !data
  ) {
    return graphData;
  }
  let last = 0;
  result = graphData?.map((key, i) => {
    if (data[key?.quarter] ? data[key?.quarter] : key?.earnings) last = i;
    return {
      quarter: key?.quarter?.toLowerCase(),
      earnings: data[key?.quarter] ? data[key?.quarter] : key?.earnings,
      last: false,
      label: data[key?.quarter] ? data[key?.quarter] : key?.earnings,
    };
  });
  result[last].last = true;
  return result;
};

export const getHourlyTarget = (productiveHours, targetLoads) => {
  // const startDate = new Date(startTime);
  // const endDate = new Date(endTime);

  // const hoursPerShift = new Date(endDate - startDate).getTime() / 3600000;
  // console.log('====================================');
  // console.log(endDate,startDate);
  // console.log('====================================');
  const targetPerHour = parseInt(targetLoads) / productiveHours;

  return Math.round(targetPerHour);
};

export const getAlert = (
  timeElapsed,
  productiveHours,
  actualLoads,
  targetLoads
) => {
  const targetPerHour = getHourlyTarget(productiveHours, targetLoads);
  return (
    parseInt(actualLoads) <
    parseInt(targetPerHour) *
    Math.min(Math.floor(timeElapsed / 3600), productiveHours) &&
    parseInt(actualLoads) < parseInt(targetLoads)
  );
};

// export const getAlertHourly = (startTime, endTime, currLoads, hourlyTarget) => {
//   const date =
//     new Date(endTime).getTime() > utcToNZTime().date.getTime()
//       ? utcToNZTime().date
//       : new Date(new Date(endTime).getTime() - 60000);
//   if (hourlyTarget == 0) return false;
//   const timeSections = Math.round(60 / hourlyTarget);
//   date.setHours(date.getHours(), 0, 0, 0);
//   const timeElapsed = timeDiff(date);
//   return (
//     parseInt(currLoads ? currLoads : 0) <
//     Math.floor(Math.min(Math.floor(timeElapsed / 60), 60) / timeSections) &&
//     parseInt(currLoads ? currLoads : 0) < parseInt(hourlyTarget)
//   );
// };

export function utcToNZTime(utcTime = new Date().toUTCString()) {
  if (!utcTime) return "";
  const here = new Date(utcTime);
  const invdate = new Date(
    here.toLocaleString("en-US", {
      timeZone: "Pacific/Auckland",
    })
  );

  const diff = here.getTime() - invdate.getTime();
  const date = new Date(here.getTime() - diff);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  let formattedTime = `${hours % 12 || 12}:${minutes < 10 ? "0" : ""
    }${minutes}`;
  formattedTime += hours >= 12 ? " PM" : " AM";
  return { formattedTime, date };
}

export function timeDiff(nzTime) {
  const currentTime = utcToNZTime().date;
  // console.log(nzTime);
  const startingTime = new Date(nzTime);
  return (currentTime.getTime() - startingTime.getTime()) / 1000;
}

export function isCurrentBar(currTime, shiftEndTime) {
  const nzTime = utcToNZTime().date;
  const shiftEndDate = new Date(shiftEndTime);
  const [time, meridian] = currTime.split(" ");
  const barHours = Number(time) + (meridian === "am" ? 0 : 12);
  if (
    nzTime.getHours() === barHours &&
    nzTime.getTime() <= shiftEndDate.getTime()
  ) {
    return true;
  } else {
    return false;
  }
}

export const isValidNumber = (str) => {
  const num = parseFloat(str);
  return !isNaN(num) && isFinite(num);
};

export function secondsToHhMm(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const hoursText = hours < 10 ? `0${hours}` : `${hours}`;
  const minutesText = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsText =
    remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
  return `${hoursText}:${minutesText}:${secondsText} hrs`;
}

export function getDurationOfCurrentDayInSeconds(
  endTime,
  startTime,
  currentDate
) {
  const startMoment = moment.unix(startTime);
  const endMoment = moment.unix(endTime);

  // Set the time to 00:00:00 for the current date
  const currentDateStart = moment(currentDate).startOf("day");

  if (endMoment.isBefore(currentDateStart)) {
    return endTime - startTime; // Return duration in seconds
  }
  // If startMoment is before the currentDateStart, use currentDateStart as the start point
  const effectiveStartMoment = startMoment.isBefore(currentDateStart)
    ? currentDateStart
    : startMoment;

  // If endMoment is after the end of the current day, use the end of the current day as the end point
  const effectiveEndMoment = endMoment.isAfter(
    currentDateStart.clone().endOf("day")
  )
    ? currentDateStart.clone().endOf("day")
    : endMoment;

  const duration = effectiveEndMoment.diff(effectiveStartMoment, "seconds"); // Calculate duration in seconds

  return duration;
}

export function getDurationOfCurrentDayInHHMMSS(
  endTime,
  startTime,
  currentDate
) {
  const startMoment = moment.unix(startTime);
  const endMoment = moment.unix(endTime);

  // Set the time to 00:00:00 for the current date
  const currentDateStart = moment(currentDate).startOf("day");

  if (endMoment.isBefore(currentDateStart)) {
    return moment
      .utc(moment.duration(endTime - startTime, "seconds").asMilliseconds())
      .format("HH:mm:ss");
  }
  // If startMoment is before the currentDateStart, use currentDateStart as the start point
  const effectiveStartMoment = startMoment.isBefore(currentDateStart)
    ? currentDateStart
    : startMoment;

  // If endMoment is after the end of the current day, use the end of the current day as the end point
  const effectiveEndMoment = endMoment.isAfter(
    currentDateStart.clone().endOf("day")
  )
    ? currentDateStart.clone().endOf("day")
    : endMoment;

  const duration = moment.duration(
    effectiveEndMoment.diff(effectiveStartMoment)
  );

  // Format the duration as HH:MM:SS
  const formattedDuration = moment
    .utc(duration.asMilliseconds())
    .format("HH:mm:ss");

  return formattedDuration;
}

export function calculateDistance(lat1, lon1, lat2, lon2) {
  const earthRadius = 6371; // Radius of the Earth in kilometers

  // Convert latitude and longitude from degrees to radians
  const radLat1 = (Math.PI * lat1) / 180;
  const radLon1 = (Math.PI * lon1) / 180;
  const radLat2 = (Math.PI * lat2) / 180;
  const radLon2 = (Math.PI * lon2) / 180;

  // Haversine formula
  const dLat = radLat2 - radLat1;
  const dLon = radLon2 - radLon1;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(radLat1) *
    Math.cos(radLat2) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Calculate the distance in kilometers
  const distance = earthRadius * c;

  return distance?.toFixed(1);
}

export const joinWithAnd = (array) => {
  if (array.length === 0) {
    return "";
  } else if (array.length === 1) {
    return array[0];
  } else {
    const joined =
      array.slice(0, -1).join(", ") + " & " + array[array.length - 1];
    return joined;
  }
};

const STAGING_URL = 'https://api.optitrac.com/imos-staging';
const PRODUCTION_URL = 'https://api.optitrac.com/imos-production';
export const getUrl = (endpoint) => {
  let baseUrl = '';
  if (process.env.REACT_APP_ENV === 'stage') baseUrl = STAGING_URL;
  else baseUrl = PRODUCTION_URL;
  if (endpoint) baseUrl += endpoint;
  else baseUrl += '/';
  return baseUrl;
};