import React, { useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import ActionMenu from "../pages/ShiftPlanning/components/ActionMenu/ActionMenu";
import Logo from "../assets/svgs/favicon.png";
import SelectField from "../pages/ShiftPlanning/components/GeneralFields/SelectField";
import Summary from "pages/Notifications/SubComponent/NotificationSummary/Summary";
import useNotificationContext from "hooks/useNotificationContext";

const departments = [
  { name: "KPI Dashboard", code: 100, allowedRoles: [300, 400, 500] },
  { name: "Shift Management", code: 200, allowedRoles: [300, 400, 500] },
  { name: "Dump Management", code: 300, allowedRoles: [300, 400, 500] },
  {
    disabled: process.env.REACT_APP_ENV != "stage",
    name: "Asset Tracking",
    code: 400,
    allowedRoles: [300, 400, 500],
  },
  { name: "Maintenance", code: 500, allowedRoles: [300, 400, 500, 600] },
  { name: "Pre-start Checks", code: 600, allowedRoles: [300, 400, 500] },
  { name: "User Management", code: 700, allowedRoles: [300, 400, 500] },
];

const links = {
  100: "/management-kpis/kpi-dashboard",
  200: "/shift-management/shift-planning",
  300: "/dump-management/landing-page",
  400: "/asset-tracking",
  500: "/maintainance/landing-page",
  500: "/maintenance/workshop-jobs",
  600: "/pre-start-check/landing-page",
  700: "/user-management/landing-page",
};

const linksToCode = {
  "management-kpis": 100,
  "shift-management": 200,
  "dump-management": 300,
  "asset-tracking": 400,
  maintenance: 500,
  "pre-start-check": 600,
  "user-management": 700,
};

const ck = (path) => {
  const finalPath =
    path.split("/")[1] == "landing-page" ? undefined : path.split("/")[1];
  if (!finalPath) return undefined;
  const value = linksToCode[finalPath];
  // const label = departments.find((x) => x.code == value).name
  return { value, label: "" };
};
const Header = ({ pageName, showlogo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, logout } = useAuth();
  const { showNotificationSummary, setShowNotificationSummary, unreadNotifications } = useNotificationContext();
  const [ShowAccountPopUp, setShowAccountPopUp] = useState(false);
  const [currentSection, setCurrentSection] = useState(ck(location.pathname));

  const openNotificationSummaryModal = (e) => {
    e.preventDefault();
    setShowNotificationSummary(!showNotificationSummary);
  };

  useEffect(() => {
    setCurrentSection(ck(location.pathname));
  }, [location]);

  return (
    <>
      {showlogo && (
        <div>
          <img src={Logo} alt="imos" srcset="" style={{ width: "150px" }} />
        </div>
      )}

      <div className="top_heading">
        <h2 style={{ marginBottom: 0 }}>{pageName}</h2>
      </div>
       
      <div className="top_right d-flex align-items-center">
        {currentSection && (
          <SelectField
            noPlaceHolder={true}
            options={departments
              ?.filter((x) => !x.disabled)
              .map((e) => ({
                value: e.code,
                label: e.name,
              }))}
            value={currentSection.value}
            onChange={(v) => {
              // setCurrentSection(v)
              navigate(links[v]);
            }}
            style={{
              height: "30px",
              width: "160px",
              marginTop: 2.5,
              borderRadius: "15px",
              backgroundColor: "white",
              paddingLeft: "10px",
              border: "1px solid #E1E8F4",
              color: "#1A1A1A",
              fontSize: "12px",
              fontWeight: "600",
            }}
            mainContainerStyle={{ marginBottom: "0px" }}
          />
        )}
        <ul>
          <li className="header_dropdown_li">
            <a href="" onClick={openNotificationSummaryModal} className="position-relative">
              <i
                style={{ color: "#FFF", fontSize: "1rem" }}
                className="fa-regular fa-bell"
              ></i>
              {unreadNotifications !== 0 && <div style={{
                top: -5,
                right: -5,
                position: 'absolute',
                backgroundColor: '#D54444',
                borderRadius: '1000px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white', 
                fontSize: '10px', 
                fontWeight: 'bold',
                height: '16px',
                width: '16px',
              }}>
                {unreadNotifications}
              </div>}
            </a>
            {showNotificationSummary && (<Summary/>)}
          </li>

          <li>
            <ActionMenu
              isOpen={ShowAccountPopUp}
              onClick={(e) => {
                setShowAccountPopUp(!ShowAccountPopUp);
              }}
              icon={
                <div className="account-icon">
                  {auth?.userData?.firstName?.charAt(0)}
                  {auth?.userData?.lastName?.charAt(0)}
                </div>
              }
              options={[
                {
                  name: "My Account",
                  onClick: () => (window.location.href = "/myaccount"),
                },
                { name: "LogOut", onClick: () => logout() },
              ]}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
