export const dayShiftData = [
  { quarter: '6 AM', earnings: 0 },
  { quarter: '7 AM', earnings: 0 },
  { quarter: '8 AM', earnings: 0 },
  { quarter: '9 AM', earnings: 0 },
  { quarter: '10 AM', earnings: 0 },
  { quarter: '11 AM', earnings: 0 },
  { quarter: '12 PM', earnings: 0 },
  { quarter: '1 PM', earnings: 0 },
  { quarter: '2 PM', earnings: 0 },
  { quarter: '3 PM', earnings: 0 },
  { quarter: '4 PM', earnings: 0 },
];

export const nightShiftData = [
  { quarter: '5 PM', earnings: 0 },
  { quarter: '6 PM', earnings: 0 },
  { quarter: '7 PM', earnings: 0 },
  { quarter: '8 PM', earnings: 0 },
  { quarter: '9 PM', earnings: 0 },
  { quarter: '10 PM', earnings: 0 },
  { quarter: '11 PM', earnings: 0 },
  { quarter: '12 AM', earnings: 0 },
  { quarter: '1 AM', earnings: 0 },
  { quarter: '2 AM', earnings: 0 },
  { quarter: '3 AM', earnings: 0 },
];

export const departmentList = () => [
  {
    disabled: process.env.REACT_APP_ENV != 'stage',
    name: 'KPI Dashboard',
    code: 100,
    allowedRoles: [300, 400, 500],
  },
  {
    disabled: false,
    name: 'Asset Tracking',
    code: 400,
    allowedRoles: [300, 400, 500],
  },
  { name: 'Shift Management', code: 200, allowedRoles: [300, 400, 500] },
  { name: 'Dump Management', code: 300, allowedRoles: [300, 400, 500] },
  { name: 'Pre-Start Checks', code: 600, allowedRoles: [300, 400, 500] },
  {
    disabled: false,
    name: 'Maintenance',
    code: 500,
    allowedRoles: [300, 400, 500, 600],
  },
  { name: 'User Management', code: 700, allowedRoles: [300, 400, 500] },
];

export const API_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};


export const ENDPOINTS = {
  login: 'auth/login',
  forgotPassword: 'auth/forgot-password',
  resetPassword: 'users/reset-password',
  getZones: 'zone/',
  getDailyZones: 'jobs/get-zones',
  getActiveOperators: 'users/active-operators',
  logout: 'auth/logout',
  editHazards: 'zone/edit-hazard',
  getTrucks: 'trucks',
  changeZoneStatus: 'zone/change-status',
  getAllUsers: 'users/users-list',
  addUser: 'users/add-user',
  resendInvitation: 'users/resend-invitation',
  cancelInvitation: 'users/cancel-invitation',
  editUser: 'users/edit-user',
  deactivateDeleteUsers: 'users/deactivate-delete',
  getDepartments: 'getdepartments',
  getLicenses: 'getlicenses',
  getNewToken: 'auth/refresh-token',
  setTimer: (zone, reminderTime) =>
    `zone/set-timer?zone=${zone}&timer=${reminderTime}`,
  getWaterConsumption: 'water-usage',
  changePassword: 'users/change-password',
  authChangePassword: 'auth/change-password',
  dismissAlert: (zone) => `zone/dismiss-alert?zone=${zone}`,
  getJobs: 'jobs/jobs-list',
  getJob: 'jobs/get-job',
  getJobDailyReport: 'reports/jobs-daily-report',
  getZonesReport: 'reports/zone-daily-report',
  getSingleJob: 'jobs/single-job',
  getRefills: 'waterRefill/',
  validateToken: 'auth/validate-token',
  generatePin: 'users/generate-pin',
  getPrestartChecks: 'prestart/',
  getsummarypdf: 'prestart/summary-pdf',
  getImage: 'prestart/image',
  getReportPdf: 'prestart/report-pdf',
  signPrestartCheck: 'prestart/sign',
  getCheckPoints: 'checkpoints/',
};

export const headerText = [
  'User Name',
  'Status',
  'User Type',
  'Email',
  'Department',
  'Equipment License',
  'PIN No',
  'Added by',
  'Actions',
];

export const headerText2 = [
  'Asset ID',
  'Department',
  'Status',
  'Ref No.',
  'Operator',
  'Login/Engin On',
  'Check Time',
  'Failed Checks',
  'Engine Hours',
  "Supervisor's Signature",
];
export const headerText3 = ['', 'Checklist', 'Answer'];
export const UserType = {
  admin: { value: 'Admin', name: 'Super Admin' },
  supervisor: { value: 'Supervisor', name: 'Supervisor' },
  operator: { value: 'Operator', name: 'Operator' },
};
export const UserStatus = {
  invited: 'Invited',
  inactive: 'Inactive',
  active: 'Active',
};

export const ZoneStatus = {
  Open: 'Open',
  Completed: 'Completed',
  Alerted: 'Alerted',
  Progress: 'In progress',
};
export const sections = {
  100: 'Dust Control',
  200: 'Material Movements',
  300: 'Maintenance',
  400: 'Scrap Bins',
  500: 'KPIs',
  600: 'Pre-Start Checks',
  700: 'User Management',
  1100: 'Asset Tracking',
};
export const subSections = {
  100: [
    'Dust control progress',
    'Reports',
    'Jobs history',
    'Water towers interactions',
  ],
  200: [],
  300: [],
  400: [],
  500: [],
  600: [],
  700: [],
  1100: ['Live tracking', 'Trips history'],
};


export const desiredOrder = [500, 400, 100, 200, 300, 600, 700, 1100];

export const userType = {
  Supervisor: 'Supervisor',
  Admin: 'Super Admin',
  Operator: 'Operator',
};

export const WebSocketURL =
  process.env.REACT_APP_ENV === 'stage'
    ? 'wss://ws.optitrac.com/imos-staging?application='
    : 'wss://ws.optitrac.com/imos-production?application=';
