import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = ({ children, pageName }) => {

  return (
    <section className="manage_dashboard">
      <Sidebar />
      <div className="right_sidebar" id="RightSidebar">
        <div className="top_header">
          <Header pageName={pageName} />
        </div>
        {children}
      </div>
    </section>
  );
};

export default Layout;
