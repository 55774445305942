import { useContext, useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../context/globalContext';
import { startCase } from 'lodash';
import AuthContext from '../context/AuthProvider';
import { sections, subSections } from './constants';
import Unauthorized from '../components/Unauthorized';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Layout from '../components/Layout';
import { UserContext } from '../context/userContext';

const getContainerClassNameByDepartment = (departmentCode) => {
  if (departmentCode === 200 || departmentCode === 800) {
    return 'inner-container-not-overflow ';
  } else if (departmentCode === 400) {
    //scrap bins
    return 'inner-container';
  } else return 'mx-3 inner-container';
};

export const withAccessControl = (Component, defaultSelectedTab) => (props) => {
  const { code: departmentCode } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { showLiveAssetTracking, setShowLiveAssetTracking } =
    useGlobalContext();

  const getSelectedTabFromUrl = () => {
    if (departmentCode == 800) {
      if (queryParams?.get('type') == 'live') {
        return 0;
      } else if (queryParams?.get('type') == 'history') {
        return 1;
      }
    }
    // write more according to department logic
    return defaultSelectedTab ?? 0;
  };

  const handleTabChange = (newTab) => {
    navigate({
      pathname: location.pathname,
      search: '?',
      replace: true,
    });
    setSelectedTab(newTab);
  };

  const { userInfo, departments } = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState(getSelectedTabFromUrl);
  const [selectedSubText, setSelectedSubText] = useState('');
  const [sidebarActive, setSidebarActive] = useState(true);
  const [showBack, setShowBack] = useState(false);

  const handleOnBack = () => {
    if (showLiveAssetTracking && departmentCode === 800) {
      setShowLiveAssetTracking(false);
      setShowBack(false);
    }
  };

  useEffect(() => {
    if (showLiveAssetTracking && departmentCode === 800) {
      setSelectedSubText('Live Tracking');
      setShowBack(true);
    } else {
      setShowLiveAssetTracking(false);
      setShowBack(false);
      const subTexts = subSections[departmentCode];
      if (subTexts && subTexts.length > 0) {
        setSelectedSubText(startCase(subTexts[selectedTab] || subTexts[0]));
      } else {
        setSelectedSubText('');
      }
    }
  }, [
    departmentCode,
    selectedTab,
    showLiveAssetTracking,
    setShowLiveAssetTracking,
  ]);

  useEffect(() => {
    const subTexts = subSections[departmentCode];
    if (subTexts && subTexts.length > 0) {
      setSelectedSubText(subTexts[selectedTab] || subTexts[0]);
    } else {
      setSelectedSubText('');
    }
  }, [departmentCode, selectedTab]);

  const department = useMemo(() => {
    return departments?.find(({ code }) => code === departmentCode);
  }, [departments, departmentCode]);
  //!Temp comment
  // if (
  //   !userInfo?.departments?.includes(department?.code) &&
  //   department?.is_restricted
  // )
  //   return <Unauthorized />;
  // else
  return (
    <Layout pageName={props.pageName} >
      <div style={{ backgroundColor: 'white', height: '100%', lineHeight: 'initial' }}>
        <Component
          {...props}
          sidebarActive={sidebarActive}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
    </Layout>
  );
};
